import { Brand } from "./homepageHero.types";
import Glide from '@glidejs/glide'

const homeHero = document.querySelector(".homepage-hero");

if (homeHero) {
    let db: Brand[];
    let heroBottom = homeHero.querySelector(".homepage-hero__bottom") as HTMLDivElement;
    let brandDropdown = homeHero.querySelector("select[name=select-brands]") as HTMLSelectElement;
    let industryDropdown = homeHero.querySelector("select[name=select-industries]") as HTMLSelectElement;
    let categoryDropdown = homeHero.querySelector("select[name=select-applications]") as HTMLSelectElement;
    let productDropdown = homeHero.querySelector("select[name=select-products]") as HTMLSelectElement;
    let searchButton = heroBottom?.querySelector(".link") as HTMLButtonElement;

    let selectedIndustry: HTMLOptionElement,
        selectedApplication: HTMLOptionElement,
        selectedProduct: HTMLOptionElement,
        selectedBrand: HTMLOptionElement;

    let activeDropdown: HTMLSelectElement | null = null;

    const populateDb = () => {
        db = JSON.parse(heroBottom.dataset.herodropdown);
    }

    const initGlide = () => {
        if (homeHero.querySelector('.homepage-hero__carousel')) {
            new Glide('.glide', {
                type: 'carousel',
                perView: 1,
                perSwipe: "|",
                autoplay: 5000
            }).mount();
        }
    }

    const init = () => {
        initGlide();

        // Uncomment to bring back mega search bar
        // updateSearchButton();
        // populateDb();
        // populateBrands();
        // hookListeners();
        // resetSelectsToPlaceholder();

        // window.addEventListener('pageshow', () => {
        //     resetSelectsToPlaceholder();
        // });
    };

    const resetSelectsToPlaceholder = () => {
        brandDropdown.selectedIndex = 0;
        industryDropdown.selectedIndex = 0;
        categoryDropdown.selectedIndex = 0;
        productDropdown.selectedIndex = 0;
    }

    const updateSearchButton = () => {
        const link = generateLink();

        if (link) {
            searchButton.classList.remove("disabled");
            searchButton.setAttribute("href", link);
        } else {
            searchButton.classList.add("disabled");
            searchButton.removeAttribute("href");
        }
    }

    const generateLink = () => {
        //activeDropdown?.selectedOptions[0].value || "";
        if (selectedProduct) return selectedProduct.dataset.url;
        if (selectedApplication) return selectedApplication.dataset.url;
        if (selectedIndustry) return selectedIndustry.dataset.url;
        if (selectedBrand) return selectedBrand.dataset.url;
        return "";
    }

    const hookListeners = () => {
        brandDropdown?.addEventListener("change", (e: Event) => {
            const target = e.target as HTMLSelectElement;
            activeDropdown = target;

            let selectedOption = target.options[target.selectedIndex];
            selectedBrand = selectedOption;
            populateIndustries(activeDropdown.selectedOptions[0].value);
            updateSearchButton();
        });


        industryDropdown?.addEventListener("change", (e: Event) => {
            const target = e.target as HTMLSelectElement;
            activeDropdown = target;

            let selectedOption = target.options[target.selectedIndex];
            selectedIndustry = selectedOption;
            populateCategories(activeDropdown.selectedOptions[0].value);
            updateSearchButton();
        });

        categoryDropdown?.addEventListener("change", (e: Event) => {
            const target = e.target as HTMLSelectElement;
            activeDropdown = target;

            let selectedOption = target.options[target.selectedIndex];
            selectedApplication = selectedOption;
            populateProducts(activeDropdown.selectedOptions[0].value);
            updateSearchButton();
        });

        productDropdown?.addEventListener("change", (e: Event) => {
            const target = e.target as HTMLSelectElement;
            activeDropdown = target;

            let selectedOption = target.options[target.selectedIndex];
            selectedProduct = selectedOption;
            updateSearchButton();
        });
    }

    const clearOptions = (selectElement: HTMLSelectElement | null) => {
        for (let i = selectElement.options.length - 1; i > 0; i--) {
            selectElement.remove(i);
        }

        selectElement.selectedIndex = 0;
    }

    const addOption = (selectElement: HTMLSelectElement | null, value: string, text: string, url: string) => {
        const option = document.createElement("option");
        option.value = value;
        option.text = text;
        option.dataset.url = url;
        selectElement.add(option);
    }

    const populateBrands = () => {
        if (!brandDropdown) return;

        clearOptions(brandDropdown);

        db.forEach(brand => {
            addOption(brandDropdown, brand.BrandName, `${brand.BrandName}`, brand.BrandUrl);
        });
    }

    const populateIndustries = (brandName: string) => {
        if (!industryDropdown) return;

        clearOptions(industryDropdown);
        clearOptions(categoryDropdown);
        clearOptions(productDropdown);

        let brand = db.find(b => b.BrandName === brandName);
        brand?.Industries.forEach(industry => {
            addOption(industryDropdown, industry.IndustryName, `${industry.IndustryName}`, industry.IndustryUrl);
        });
    }

    const populateCategories = (industryName: string) => {
        if (!categoryDropdown || !selectedBrand) return;

        clearOptions(categoryDropdown);
        clearOptions(productDropdown);

        let brand = db.find(b => b.BrandName === selectedBrand.value);
        let industry = brand?.Industries.find(i => i.IndustryName === industryName);
        industry?.Categories.forEach(category => {
            addOption(categoryDropdown, category.CategoryName, `${category.CategoryName} (${category.Products.length})`, category.CategoryUrl);
        });
    }

    const populateProducts = (categoryName: string) => {
        if (!productDropdown || !selectedBrand || !selectedIndustry) return;

        clearOptions(productDropdown);

        let brand = db.find(b => b.BrandName === selectedBrand.value);
        let industry = brand?.Industries.find(i => i.IndustryName === selectedIndustry.value);
        let category = industry?.Categories.find(c => c.CategoryName === categoryName);
        category?.Products.forEach(product => {
            addOption(productDropdown, product.ProductName, product.ProductName, product.ProductUrl);
        });
    }

    document.addEventListener("DOMContentLoaded", init);
}